import toggleMarkdown from "./editor/index";
import initMentions from "./editor/mentions";
const headlessTippy = require("tippy.js/headless").default;
import ArticleSearch from "./alpine/article-search";
import Billing from "./alpine/billing";
import Tabs from "./alpine/tabs";
import BoundingBoxes from "./alpine/boundingBoxes";
import SearchSelect from "./alpine/search-select";
import ManualIssueForm from "./alpine/VisualTool/manual-issue-form";
import Chart from 'chart.js/auto';
import { flare } from "@flareapp/flare-client";
import { debounce } from "lodash";
import { getBrowserName } from "./utils.js"
import accordion from "./alpine/accordion.js";

document.addEventListener("DOMContentLoaded", function () {
    // Only enable Flare in beta & production, we don't want to waste your quota while you're developing:
    if (window.aaa_env?.APP_ENV && ['production', 'beta'].includes(window.aaa_env.APP_ENV)) {
        flare.light(window.aaa_env.FLARE_JS_PUBLIC_KEY);
    }

    var skipLinkButton = document.getElementById('skip-to-link');

    setTimeout(function () {
        var skipLinkDestination = document.querySelector('#beacon-container');

        if (skipLinkDestination) {
            if (skipLinkButton) {
                skipLinkButton.addEventListener('keydown', function (event) {
                    if (event.key === 'Enter') {
                        event.preventDefault();
                        handleSkipLink();
                    }
                });
            }
        }
    }, 2000);
});

document.addEventListener('alpine:init', () => {

    Alpine.magic('clipboard', () => subject => {
        navigator.clipboard.writeText(subject)
    })

    Alpine.store('browserExtensionState', {
        enabled: false,
        init() {
            window.addEventListener('aaardvarkExtensionActive', () => {
                this.enabled = true;
            });
        }
    });

    Alpine.store('browserExtensionState').init();

    Alpine.data("ArticleSearch", ArticleSearch);
    Alpine.data("Billing", Billing);
    Alpine.data("SearchSelect", SearchSelect);
    Alpine.data("Tabs", Tabs);
    Alpine.data('Accordion', accordion);
    Alpine.data("BoundingBoxes", BoundingBoxes);
    Alpine.data("ManualIssueForm", ManualIssueForm);
})

window.toggleMarkdown = toggleMarkdown;
window.initMentions = initMentions;
window.Chartist = require("chartist");
window.Chart = Chart;
window.debounce = debounce;
window.getBrowserName = getBrowserName;

window.tippy = function (selector, tooltip) {
    if (!tooltip.length) {
        return;
    }
    headlessTippy(selector, {
        content: tooltip,
        placement: "right",
        arrow: true,
        render(instance) {
            const popper = document.createElement("div");
            popper.className = "p-2 tippy-box";

            const box = document.createElement("div");
            box.className = "max-w-sm p-2 bg-white rounded shadow";
            box.textContent = instance.props.content;

            const icon = document.createElement("div");

            popper.appendChild(box);

            function onUpdate(prevProps, nextProps) {
                if (prevProps.content !== nextProps.content) {
                    box.textContent = nextProps.content;
                }
            }

            return {
                popper,
                onUpdate, // optional
            };
        },
    });
};

function handleSkipLink() {
    var iframeContainer = document.querySelector('#beacon-container');

    if (iframeContainer) {
        var iframe = iframeContainer.querySelector('iframe');

        if (iframe) {
            var helpChatButton = iframe.contentDocument.querySelector('button');

            if (helpChatButton) {
                helpChatButton.focus();
            }
        }
    }
}
