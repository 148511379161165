let stripe;
let cardElement;
export default (config) => ({
    stripeValidationError: "",
    stripeError: false,
    stripeInitialized: false,
    processing: false,
    cardHolderName: config.cardHolderName,
    init() {
        try {
            this.setupStripe();
        } catch (err) {
            console.log(err);
            // Stripe could not be initialized.
            this.stripeError = true;
        }

        // listen for browser event "confirm-payment-intent" and call confirm payment
        window.Livewire.on("maybe-confirm-payment-intent", (data) => {
            this.maybeConfirmPaymentIntent(data[0]);
        });

    },
    processCard() {
        this.processing = true;

        stripe
            .confirmCardSetup(config.clientSecret, {
                payment_method: {
                    card: cardElement,
                    billing_details: {
                        name: this.cardHolderName,
                    },
                },
            })
            .then((result) => {
                if (result.error) {
                    this.stripeValidationError = result.error.message;

                    this.processing = false;
                } else {
                    config.$wire.call(
                        config.submitMethod,
                        result.setupIntent.payment_method
                    );
                }
            });
    },

    setupStripe() {
        if (
            !config ||
            !config.stripe_public_key
        ) {
            throw new Error("Stripe could not be initialized.");
        }

        if (this.stripeInitialized) {
            return;
        }
        stripe = new Stripe(config.stripe_public_key);
        return new Promise((resolve, reject) => {
            this.processing = true;
            if (config.submitMethod === "updateCard") {
                const elements = stripe.elements();
                cardElement = elements.create("card", {
                    style: {
                        base: {
                            iconColor: "rgb(54,47,120)",
                            color: "rgb(22,30,46)",
                            fontWeight: 500,
                            fontFamily:
                                'Inter var, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
                            fontSize: "18px",
                            fontSmoothing: "antialiased",
                            ":-webkit-autofill": {
                                color: "rgb(107,114,128)",
                            },
                            "::placeholder": {
                                color: "rgb(107,114,128)",
                            },
                        },
                        invalid: {
                            iconColor: "rgb(224,36,36)",
                            color: "rgb(224,36,36)",
                        },
                    },
                });

                cardElement.mount("#card-element");
            }
            this.processing = false;
            this.stripeInitialized = true;
            resolve();
        });
    },
    maybeConfirmPaymentIntent(data) {
        let paymentIntent = data.payment_intent;
        let skip_upgrade = data.skip_upgrade ?? false;
        let metadata = data.metadata ?? {};

        stripe.confirmCardPayment(paymentIntent.client_secret).then((result) => {
            if (result.error) {
                window.Livewire.dispatch("paymentIntentFailed", { data: { error: result.error } });

            } else if (result.paymentIntent.status === "succeeded") {
                let paymentIntent = result.paymentIntent;
                paymentIntent.metadata = metadata;
                window.Livewire.dispatch("paymentIntentConfirmed", { data: { paymentIntent: paymentIntent, 'skip_upgrade': skip_upgrade } });
            }

        });
    },

});
